import { InvoiceItem, QuoteItem } from "@/types";
import sortBy from "lodash/sortBy";
import { INDIVIDUAL_ITEMS } from "@/features/quote-management/quote-management-reducers";

export const calculateItemGroup = (items: QuoteItem[] | InvoiceItem[] = []) => {
  const c: { [x: string]: QuoteItem[] } = {
    [INDIVIDUAL_ITEMS]: [],
  };
  const categorySortOrder = {
    [INDIVIDUAL_ITEMS]: 0,
  };
  items?.forEach((i, idx) => {
    if (i.account_customer_category_id && !c[i.account_customer_category_id]) {
      c[i.account_customer_category_id] = [];
      if (i.account_customer_category_id !== INDIVIDUAL_ITEMS) {
        categorySortOrder[i.account_customer_category_id] = Object.keys(categorySortOrder).length;
      }
    }

    c[i.account_customer_category_id || INDIVIDUAL_ITEMS].push({
      ...i,
      sort_order: idx,
    });
  });

  const categories = sortBy(Object.keys(c), (k) => categorySortOrder[k]);

  return {
    categories,
    itemDict: c,
    categorySortOrder,
  };
};
