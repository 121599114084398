import Box from "@mui/material/Box";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import useAppSelector from "@/hooks/useAppSelector";
import FormattedNumber from "@/components/FormattedNumber";
import { InvoiceItem, InvoiceStatus, QuantityMeasurement } from "@/types";
import { getInvoiceTotals } from "@/utils/calculations";
import { getChangeAmount, getDollarsFromInt, getIntFromDollars, calculatePriceModifier } from "@/utils/calculations";
import Decimal from "decimal.js-light";
import { Fragment } from "react";
import { INDIVIDUAL_ITEMS } from "@/features/invoice-management/invoice-management-reducers";
import { selectInvoice, selectInvoicePreview } from "@/features/invoice-management/invoice-management-selectors";
import useAccount from "@/features/account-management/useAccount";
import EditableAvatar from "@/features/builder/EditableAvatar";
import { InvoicePreviewState } from "@/types";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import FormattedPhone from "@/components/FormattedPhone";
import { padWithZeros } from "@/utils/stringUtils";
import { DateTime } from "luxon";
import { calculateItemGroup } from "@/features/builder/utils";

const InvoicePreview = ({
  sync = false,
  previewState,
  isOnPil = false,
}: {
  sync: boolean;
  previewState: InvoicePreviewState;
  isOnPil?: Boolean;
}) => {
  const invoice = useAppSelector(sync ? selectInvoice : selectInvoicePreview);
  const { customers, account: accountFromInvoice } = invoice;
  // In the preview we only want to show the first customer of the list, makes things easier
  const customer = customers?.[0];
  // for draft case
  const { account: accountFromPortal } = useAccount();
  let account = accountFromInvoice || accountFromPortal;
  const { categories, itemDict } = calculateItemGroup(invoice?.items || []);

  const invoiceModifier = {
    id: invoice?.account_pricemodifier_id,
    name: invoice?.pricemodifier_name,
    value_pct: invoice?.pricemodifier_value_pct,
    value_flat: invoice?.pricemodifier_value_flat,
  };

  const { subTotal, invoiceTotal } = getInvoiceTotals(invoice);

  const amount = getChangeAmount(getIntFromDollars(subTotal), 1, invoiceModifier);

  const { name: invoice_pm_name } = calculatePriceModifier(invoice);

  const isSameDate = invoice?.statement_period_start === invoice?.statement_period_end;

  return (
    <Box padding={2}>
      <InlineContainer marginBottom="1rem" alignItems="flex-start">
        <Box flexBasis="100%" display="flex" flexDirection="column" alignItems="start">
          <Typography sx={{ fontSize: "1.2rem", fontWeight: 600, marginBottom: "1rem" }}>
            Invoice #{padWithZeros(invoice?.invoice_number || (account?.invoice_number || 0) + 1 || 0)}
          </Typography>{" "}
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {account?.public_name}
          </Typography>{" "}
          {account?.public_address1 && (
            <>
              <Typography variant="body1">{account?.public_address1}</Typography>
              {account?.public_address2 && <Typography variant="body1">{account?.public_address2}</Typography>}
              <Typography variant="body1">
                {[account?.public_city, account?.public_state, account?.public_zip_code].join(", ")}
              </Typography>
            </>
          )}
          {account?.public_email && <Typography variant="body1">{account?.public_email}</Typography>}
          {account?.public_phone && (
            <Typography variant="body1">
              <FormattedPhone value={account?.public_phone} />
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="column" flexBasis="50%" alignItems="end">
          <EditableAvatar
            isEditable={previewState === InvoicePreviewState.BUILDER && !invoice.is_readonly}
            avatar_url={account?.avatar_url}
            public_name={account?.public_name}
          />
        </Box>
      </InlineContainer>
      <InlineContainer alignItems="flex-start" marginBottom="1rem">
        <Box flexBasis="100%" display="flex" flexDirection="column" alignItems="start">
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Bill to
          </Typography>
          {customer?.name && (
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {customer?.name}
            </Typography>
          )}
          {customer?.email && !isOnPil && <Typography variant="body1">{customer?.email}</Typography>}
          {customer?.cell_phone && !isOnPil && (
            <Typography variant="body1">
              <FormattedPhone value={customer?.cell_phone} />
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="column" minWidth="20rem" alignItems="start">
          {invoice?.is_recurring && (
            <InlineContainer>
              <Typography color="primary.dark" variant="body2" sx={{ fontWeight: 500 }} noWrap>
                Recurring Invoice
              </Typography>
            </InlineContainer>
          )}
          {invoice?.due_at && (
            <InlineContainer textAlign="left" width="100%" alignItems="flex-start">
              <Typography variant="body1" sx={{ fontWeight: 600 }} minWidth="8rem">
                Due Date
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500, paddingTop: "0.1rem" }}>
                {DateTime.fromFormat(invoice?.due_at?.split("T")[0], "yyyy-MM-dd")
                  .startOf("day")
                  .toFormat("MM/dd/yyyy")}
              </Typography>
            </InlineContainer>
          )}
          {invoice?.statement_period_start && (
            <InlineContainer textAlign="left" width="100%" alignItems="flex-start">
              <Typography variant="body1" sx={{ fontWeight: 600 }} minWidth="8rem">
                Invoice Date{isSameDate ? "" : "s"}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500, paddingTop: "0.1rem" }}>
                {DateTime.fromFormat(invoice?.statement_period_start, "yyyy-MM-dd").toFormat("MM/dd/yyyy")}
                {isSameDate || !invoice?.statement_period_end
                  ? ""
                  : ` to ${DateTime.fromFormat(invoice?.statement_period_end as string, "yyyy-MM-dd").toFormat("MM/dd/yyyy")}`}
              </Typography>
            </InlineContainer>
          )}
        </Box>
      </InlineContainer>
      <InlineContainer
        sx={{
          borderRadius: "0.25rem",
          justifyContent: "space-between",
          margin: "1rem 0 0 0",
          padding: "0.25rem 1rem",
          border: "1px solid lightgrey",
        }}
      >
        <Typography variant="body2" sx={{ flexBasis: "200%" }}>
          Item Name
        </Typography>
        <Typography variant="body2" sx={{ flexBasis: "125%" }}>
          Qty/Rate
        </Typography>
        <Typography variant="body2" sx={{ flexBasis: "60%", textAlign: "right" }}>
          Amount
        </Typography>
      </InlineContainer>
      <Box marginTop="0.5rem">
        {categories?.map((category) => {
          const itemList: InvoiceItem[] = itemDict[category];
          const categoryName = itemList[0]?.account_customer_category_name;
          return (
            <Fragment key={`invoice-preview-${categoryName}-${category}`}>
              {category === INDIVIDUAL_ITEMS || !Boolean(category) ? null : (
                <InlineContainer
                  justifyContent="space-between"
                  sx={{
                    backgroundColor: "grey.400",
                    color: "black",
                    padding: "0.25rem 1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 700 }}>
                    {categoryName}
                  </Typography>
                </InlineContainer>
              )}

              {itemList?.map((item) => {
                const name = item?.name;
                const description = item?.description;

                const price = getDollarsFromInt(item?.price ?? 0);
                const lineTotal = new Decimal(price ?? 0).times(item?.quantity ?? 0).toNumber();

                const priceModifier = {
                  name: item?.pricemodifier_name,
                  value_pct: item?.pricemodifier_value_pct,
                  value_flat: item?.pricemodifier_value_flat,
                };

                const { name: pm_name } = calculatePriceModifier(item);

                let changeAmount = getChangeAmount(item?.price, item?.quantity, priceModifier);

                if (changeAmount < -lineTotal) changeAmount = -lineTotal;

                return (
                  <Box key={`invoice-preview-${item?.id || name}`} padding="0 1rem" marginBottom="0.75rem">
                    <InlineContainer justifyContent="space-between">
                      <Typography variant="body1" flexBasis="200%">
                        {name}{" "}
                        {!item?.is_recurring && invoice.is_recurring && (
                          <Box component="span" sx={{ color: "primary.dark" }}>
                            (One Time)
                          </Box>
                        )}
                      </Typography>
                      <InlineContainer flexBasis="125%">
                        <Typography variant="body2">
                          {item?.quantity}{" "}
                          {item?.quantity_measurement === QuantityMeasurement.UNIT
                            ? "Qty."
                            : `Hr${item?.quantity > 1 ? "s" : ""}.`}{" "}
                          x <FormattedNumber prefix="$" value={price} />
                        </Typography>
                      </InlineContainer>
                      <InlineContainer justifyContent="flex-end" flexBasis="60%" textAlign="right">
                        <Typography variant="body2">
                          <FormattedNumber prefix="$" value={lineTotal} />
                        </Typography>
                      </InlineContainer>
                    </InlineContainer>
                    {description && (
                      <Typography variant="body2" sx={{ paddingLeft: "1rem", color: "grey.600" }}>
                        {description}
                      </Typography>
                    )}
                    {priceModifier?.name && (
                      <InlineContainer justifyContent="space-between" width="100%" paddingLeft="1rem">
                        <Typography variant="body2" sx={{ color: "grey.600" }}>
                          {pm_name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "grey.600" }}>
                          <FormattedNumber prefix="$" value={changeAmount} />
                        </Typography>
                      </InlineContainer>
                    )}
                  </Box>
                );
              })}
            </Fragment>
          );
        })}

        <InlineContainer
          justifyContent="space-between"
          marginTop="0.5rem"
          padding="0.5rem 1rem"
          border="1px solid lightgrey"
          borderBottom="0"
          borderRadius="0.25rem 0.25rem 0 0"
        >
          <Typography variant="body1">Subtotal</Typography>
          <Typography variant="body1">
            <FormattedNumber prefix="$" value={subTotal} />
          </Typography>
        </InlineContainer>
        {Boolean(invoiceModifier?.name) && (
          <InlineContainer
            key={`invoice-preview-mods-${invoiceModifier?.id}`}
            color="grey.700"
            justifyContent="space-between"
            padding="0rem 1rem 0.5rem"
            border="1px solid lightgrey"
            borderTop="0"
            borderBottom="0"
          >
            <Typography variant="body2">
              {invoice_pm_name}{" "}
              {!invoice?.is_pricemodifier_recurring && invoice?.is_recurring && (
                <Box component="span" sx={{ color: "primary.dark" }}>
                  (One Time)&nbsp;
                </Box>
              )}
            </Typography>
            <Typography variant="body2">
              <FormattedNumber prefix="$" fixedDecimalScale value={amount} />
            </Typography>
          </InlineContainer>
        )}
        <InlineContainer
          justifyContent="space-between"
          marginBottom="1rem"
          padding="0.5rem 1rem"
          border="1px solid lightgrey"
          borderRadius="0 0 0.25rem 0.25rem"
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Total
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            <FormattedNumber prefix="$" value={invoiceTotal} />
          </Typography>
        </InlineContainer>
        {invoice?.recipient_message ? (
          <Box padding="1rem 0">
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Invoice Message
            </Typography>
            <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
              {invoice?.recipient_message}
            </Typography>
          </Box>
        ) : null}
        {![InvoiceStatus.DRAFT, InvoiceStatus.CANCELED, InvoiceStatus.SCHEDULED, InvoiceStatus.COMPLETED].includes(
          invoice?.status as InvoiceStatus,
        ) &&
          previewState === InvoicePreviewState.BUILDER && (
            <InlineContainer justifyContent="flex-end" marginTop="1rem">
              <AffirmativeButton
                sx={{ margin: "0.5rem 0 0 0", padding: "0.5rem 1.5rem" }}
                onClick={() => {
                  window.open(`/pay/${invoice.id}`);
                }}
              >
                Review & Pay
              </AffirmativeButton>
            </InlineContainer>
          )}
      </Box>
    </Box>
  );
};

export default InvoicePreview;
