import Box from "@mui/material/Box";
import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Typography from "@mui/material/Typography";
import InlineContainer from "@/components/InlineContainer";
import { matchSorter } from "match-sorter";
import { useQuery } from "@tanstack/react-query";
import { useTheme, useMediaQuery } from "@mui/material";
import { fetchAllCustomers } from "@/features/customer-management/customer-management-queries";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { useState, Fragment } from "react";
import PlusIcon from "@mui/icons-material/AddRounded";
import TextField from "@/components/TextField";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { styled } from "@mui/material";
import FormattedPhone from "@/components/FormattedPhone";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Query, DialogKeys, AccountStatuses, QuoteCustomer } from "@/types";
import useAppSelector from "@/hooks/useAppSelector";
import { selectQuote } from "@/features/quote-management/quote-management-selectors";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { useSnackbar } from "notistack";
import { setQuote } from "../quote-management-reducers";

export const CustomerGroup = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.palette.grey[200]};
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(1)};
`,
);

const CustomerCard = styled(InlineContainer)(
  ({ theme }) => `
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.common.white};
  cursor: pointer;
  padding: 0.75rem 1rem;
  width: 75%;

  &:hover {
    border: 1px solid ${theme.palette.primary.main};
  }
  
  &.selected {
    border: 2px solid ${theme.palette.primary.main};
  }

  .MuiTypography-body1 {
    color: ${theme.palette.common.black};
    font-weight: 500;
  }

  .MuiTypography-body2 {
    color: ${theme.palette.grey[700]};
  }
`,
);

export default NiceModal.create<{ prevSelectedCustomer: QuoteCustomer }>(({ prevSelectedCustomer }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [search, setSearch] = useState("");
  const modal = useModal();
  const selectedQuote = useAppSelector(selectQuote);
  const [selectedCustomer, setSelectedCustomer] = useState<QuoteCustomer>(
    prevSelectedCustomer || ({} as QuoteCustomer),
  );

  const { data }: { data?: { results?: QuoteCustomer[] } } = useQuery(
    [Query.account.INVOICE_BUILDER_ALL_CUSTOMERS],
    () => fetchAllCustomers(),
    {
      keepPreviousData: true,
    },
  );

  const customers = data?.results ?? [];

  const activeCustomers = customers.filter((c) => c.status === AccountStatuses.ACTIVE);

  const hasActiveCustomers = activeCustomers?.length > 0;

  const searchedCustomers: any = matchSorter(activeCustomers ?? [], search, {
    keys: ["name", "phone", "cell_phone", "email"],
  });

  const handleCloseModal = () => {
    modal.hide();
  };

  // Sort alphabetically by name and split into groups by first letter.
  const sortedCustomers = groupBy(
    sortBy(searchedCustomers ?? [], ["name"]),
    (customer) => Array.from(customer?.name)[0],
  );

  const handleSelectCustomer = (customer: QuoteCustomer) => {
    setSelectedCustomer(customer);
  };

  const handleAddCustomer = (customer: QuoteCustomer) => {
    let updatedCustomers: QuoteCustomer[] = [];

    const email = customer?.email || customer?.user?.email;
    const cell_phone = customer?.cell_phone || customer?.user?.cell_phone;

    updatedCustomers =
      updatedCustomers?.map((existingCustomer) => {
        // Check if the customer already exists
        if (existingCustomer.id === customer.id || existingCustomer.email === email) {
          // If the existing customer matches, update the email and cell_phone
          return {
            ...existingCustomer,
            email,
            cell_phone,
          };
        }
        // If the existing customer doesn't match, return it as it is
        return existingCustomer;
      }) ?? [];

    // Check if the customer already exists in the array
    const customerExists = updatedCustomers?.some(
      (existingCustomer) => existingCustomer.id === customer.id || existingCustomer.email === email,
    );

    // If the customer doesn't exist, add it to the end of the array
    if (!customerExists) {
      updatedCustomers?.push({
        ...customer,
        email,
        cell_phone,
      });
    }

    dispatch(
      setQuote({
        ...selectedQuote,
        has_changed: true,
        customers: updatedCustomers,
      }),
    );
    handleCloseModal();
  };

  const handleAddNewCustomer = () => {
    modal.hide();
    NiceModal.show<QuoteCustomer>(DialogKeys.CUSTOMER, {
      shouldShowBulkImport: false,
    })
      .then((newCustomer) => {
        modal.show({
          prevSelectedCustomer: newCustomer,
        });
        setSelectedCustomer(newCustomer);
      })
      .catch(() =>
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        }),
      );
  };

  const CreateNewButton = hasActiveCustomers ? NeutralButton : AffirmativeButton;

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Customers
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      {customers.length < 1 ? (
        <DialogContent sx={{ paddingTop: "3rem" }} dividers>
          {isMobile ? (
            <Typography variant="body1" sx={{ fontWeight: 500, textAlign: "center" }}>
              Looks like you don't have any customers saved in the system. To create a new customer,{" "}
              <strong>tap the "+ Create New" button</strong>.
            </Typography>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: "2.5rem",
                }}
              >
                Looks like you don't have any customers saved in the system. To create a new customer,{" "}
                <strong>click the "+ Create New" button</strong>.
              </Typography>
            </>
          )}
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            padding: { xs: "0 0 5rem 0", md: " 0rem 1.25rem  " },
          }}
          dividers
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              alignItems: "center",
              backgroundColor: "grey.200",
              textAlign: "center",
              gap: ".5rem",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                backgroundColor: "grey.500",
                color: "white",
                padding: "0.5rem 1.5rem",
                textAlign: "left",
                width: "100%",
              }}
            >
              Customer List
            </Typography>
            <TextField
              name="customer-search"
              placeholder="Search"
              sx={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
                margin: "1rem",
                width: "75%",
              }}
              onChange={(event) => setSearch(event?.target?.value)}
            />
          </Box>
          <Box>
            {Object.keys(sortedCustomers || {}).map((letter) => (
              <Fragment key={`quote-list-${letter}`}>
                <Box padding="0.5rem 1.5rem" fontWeight={700}>
                  {letter}
                </Box>
                <CustomerGroup>
                  {sortedCustomers?.[letter]?.map((customer) => (
                    <CustomerCard
                      key={`quote-customer-${customer?.id}`}
                      onClick={() => handleSelectCustomer(customer)}
                      className={selectedCustomer?.id === customer?.id ? "selected" : ""}
                    >
                      <Box>
                        <Typography variant="body1">{customer?.name}</Typography>
                        {customer?.email && <Typography variant="body2">{customer?.email}</Typography>}
                        {customer?.cell_phone && (
                          <Typography variant="body2">
                            <FormattedPhone value={customer?.cell_phone} />
                          </Typography>
                        )}
                      </Box>
                    </CustomerCard>
                  ))}
                </CustomerGroup>
              </Fragment>
            ))}
          </Box>
        </DialogContent>
      )}
      <DialogActions
        sx={{
          padding: "0.75rem 1.25rem",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {hasActiveCustomers && (
          <AffirmativeButton
            disabled={!selectedCustomer?.id}
            sx={{ width: { xs: "100%", md: 350 } }}
            onClick={() => handleAddCustomer(selectedCustomer)}
          >
            Continue
          </AffirmativeButton>
        )}
        <CreateNewButton
          onClick={handleAddNewCustomer}
          startIcon={<PlusIcon fontSize="small" />}
          sx={{ width: { xs: "100%", md: 350 } }}
        >
          Create New
        </CreateNewButton>
      </DialogActions>
    </MuiDialog>
  );
});
