import { Invoice, InvoiceStatus, InvoiceCustomer } from "@/types";
import { DateTime } from "luxon";
import { setInvoice } from "@/features/invoice-management/invoice-management-reducers";
import { Dispatch } from "react";
import stringifyQueryParams from "@/utils/stringifyQueryParams";
import isNumeric from "@/utils/isNumeric";

export const invoiceRedirectFilters = (props = {}) =>
  stringifyQueryParams({
    page: 1,
    page_size: 10,
    sort_field: "created_at",
    sort_direction: "DESC",
    ...props,
  });

export const isInvoiceComplete = (invoice: Invoice) => {
  const isStatementPeriodSelected = !!(
    (invoice?.statement_period_start && invoice?.statement_period_end) ||
    (!invoice?.statement_period_start && !invoice?.statement_period_end)
  );

  const isValidIssueDaysBefore = isNumeric(invoice?.series?.issued_days_before_due_date);

  const isRecurringInformationAllSelected =
    !!invoice?.series?.frequency && !!invoice?.series?.frequency_count && isValidIssueDaysBefore;

  const isDueDateInTheFuture =
    (invoice?.status && invoice?.status !== InvoiceStatus.DRAFT) ||
    DateTime.fromJSDate(new Date(invoice?.due_at || "")) > DateTime.now().minus({ days: 1 }).startOf("day");

  return (
    isStatementPeriodSelected &&
    (!invoice?.is_recurring || isRecurringInformationAllSelected) &&
    invoice?.expected_issue_at &&
    invoice?.due_at &&
    invoice?.customers?.length > 0 &&
    isDueDateInTheFuture
  );
};

export const handleAddCustomerToInvoice = (dispatch: Dispatch<any>, customers: InvoiceCustomer[], invoice: Invoice) => {
  let updatedCustomers: InvoiceCustomer[] = [];

  customers?.forEach((customer) => {
    const email = customer?.email || customer?.user?.email;
    const cell_phone = customer?.cell_phone || customer?.user?.cell_phone;

    updatedCustomers =
      updatedCustomers?.map((existingCustomer) => {
        // Check if the customer already exists
        if (existingCustomer.id === customer.id || existingCustomer.email === email) {
          // If the existing customer matches, update the email and cell_phone
          return {
            ...existingCustomer,
            email,
            cell_phone,
          };
        }
        // If the existing customer doesn't match, return it as it is
        return existingCustomer;
      }) ?? [];

    // Check if the customer already exists in the array
    const customerExists = updatedCustomers?.some(
      (existingCustomer) => existingCustomer.id === customer.id || existingCustomer.email === email,
    );

    // If the customer doesn't exist, add it to the end of the array
    if (!customerExists) {
      updatedCustomers?.push({
        ...customer,
        email,
        cell_phone,
      });
    }
  });

  dispatch(
    setInvoice({
      ...invoice,
      has_changed: true,
      customers: updatedCustomers,
    }),
  );
};
