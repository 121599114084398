import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NeutralButton from "@/components/buttons/NeutralButton";
import { Invoice } from "@/types";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { Divider, Switch, TextField, useMediaQuery, useTheme } from "@mui/material";
import { CustomerCellPhone, CustomerEmail } from "@/features/builder/CustomerCard";
import EmailIcon from "@mui/icons-material/MailOutlineRounded";
import { useState } from "react";
import PhoneIcon from "@mui/icons-material/PhoneIphoneRounded";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import PlusIcon from "@mui/icons-material/AddRounded";

export default NiceModal.create<{ invoice: Invoice }>(({ invoice }) => {
  const modal = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleCloseModal = () => {
    modal.hide();
  };
  // @ts-ignore
  const customer = invoice?.customers?.[0] || invoice?.account_customer;
  const [emailChecked, setEmailChecked] = useState(!!customer?.email);
  const [smsChecked, setSmsChecked] = useState(!!customer?.cell_phone);
  const [focusCC, setFocusCC] = useState<number | null>(null);
  const [email_cc_recipients, setEmailCCRecipients] = useState<string[]>([]);

  const ccDisabled = !emailChecked;

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.dark",
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Share via Email/Text
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: "grey.500",
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "2rem 1.25rem" }} dividers>
        <Typography sx={{ marginBottom: "0.5rem", fontWeight: "bold" }}>Bill to</Typography>
        <Typography sx={{ marginBottom: "0.5rem" }}>{customer?.name}</Typography>
        <CustomerEmail email={customer?.email} />
        <CustomerCellPhone cell_phone={customer?.cell_phone} />
        <Divider sx={{ margin: "1.5rem 0" }} />
        <Typography sx={{ marginBottom: "0.5rem", fontWeight: "bold" }}>Share via</Typography>
        <InlineContainer justifyContent="space-between" marginBottom="0.5rem">
          <InlineContainer>
            <EmailIcon fontSize="small" sx={{ marginRight: 1 }} />
            <Typography variant="body2">Email</Typography>
          </InlineContainer>
          <InlineContainer>
            <Switch
              size="small"
              sx={{ marginLeft: "0.5rem" }}
              checked={emailChecked}
              onChange={(event) => {
                setEmailChecked(event.target.checked);
                setSmsChecked(smsChecked || !event.target.checked);
                setEmailCCRecipients([]);
              }}
              disabled={!customer?.email || !customer?.cell_phone}
            />
            <Typography variant="body2">{emailChecked ? "ON" : "OFF"}</Typography>
          </InlineContainer>
        </InlineContainer>
        <InlineContainer justifyContent="space-between" marginBottom="1.5rem">
          <InlineContainer>
            <PhoneIcon fontSize="small" sx={{ marginRight: 1 }} />
            <Typography variant="body2">Cell Phone</Typography>
          </InlineContainer>
          <InlineContainer>
            <Switch
              size="small"
              sx={{ marginLeft: "0.5rem" }}
              checked={smsChecked}
              onChange={(event) => {
                setEmailChecked(smsChecked || !event.target.checked);
                setSmsChecked(event.target.checked);
              }}
              disabled={!customer?.email || !customer?.cell_phone}
            />
            <Typography variant="body2">{smsChecked ? "ON" : "OFF"}</Typography>
          </InlineContainer>
        </InlineContainer>
        <Typography variant="body1" sx={{ marginBottom: "0.25rem", fontWeight: "bold" }}>
          CC Recipients (Optional)
        </Typography>
        {email_cc_recipients?.map((email, index) => (
          <InlineContainer
            key={index}
            justifyContent="space-between"
            marginBottom="0.5rem"
            maxWidth="22.5rem"
            alignItems="flex-start"
          >
            <TextField
              autoFocus
              value={email}
              fullWidth
              size="small"
              onFocus={(event) => {
                setFocusCC(index);
                event.target.focus();
              }}
              onBlur={() => setFocusCC(null)}
              onChange={(event) => {
                setEmailCCRecipients(
                  email_cc_recipients.map((e, i) => (i === index ? (event.target.value || "")?.trim() : e)),
                );
              }}
              {...(!email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/) && focusCC !== index
                ? {
                    error: true,
                    helperText: "Incorrect email format",
                  }
                : {})}
              placeholder="abc@gmail.com"
            />
            <DeleteIcon
              fontSize="small"
              onClick={() => {
                setEmailCCRecipients(email_cc_recipients.filter((_, i) => i !== index));
              }}
              sx={{
                ":hover": { cursor: "pointer" },
                marginLeft: "1rem",
                marginTop: "0.5rem",
              }}
            />
          </InlineContainer>
        ))}
        {invoice?.is_recurring && (
          <Typography
            variant="body2"
            fontWeight={600}
            marginBottom="0.5rem"
            sx={{ color: "grey.600", fontWeight: 400 }}
          >
            CC recipients are set to recur.
          </Typography>
        )}
        <Typography
          variant="body2"
          display="inline"
          sx={{
            fontWeight: 600,
            cursor: ccDisabled ? "not-allowed" : "pointer",
            color: ccDisabled ? "grey.400" : "primary.main",
          }}
          onClick={() => {
            if (!ccDisabled) {
              setEmailCCRecipients([...email_cc_recipients, ""]);
            }
          }}
        >
          <PlusIcon fontSize="small" sx={{ position: "relative", top: "0.35rem", marginRight: "0.25rem" }} />
          Add recipient
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            disableElevation
            onClick={() => {
              modal.reject();
              handleCloseModal();
            }}
            sx={{ fontSize: isMobile ? "small" : "medium" }}
          >
            Cancel
          </NeutralButton>
          <AffirmativeButton
            onClick={() => {
              modal.resolve({
                is_send_via_email: emailChecked,
                is_send_via_sms: smsChecked,
                email_cc_recipients: email_cc_recipients.filter((email) =>
                  email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/),
                ),
              });
              handleCloseModal();
            }}
            sx={{ fontSize: isMobile ? "small" : "medium" }}
          >
            Send Now
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
